<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-else>
          <crud-list
            v-model="logs"
            :headers="headers"
            :item-class="itemRowBackground"
            :sort-by="['logged_at']"
            :sort-desc="[true]"
            :slots="['item.logged_at', 'item.status', 'item.data']"
            export-pdf-xlsx
          >
            <template slot="datas">
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template v-slot:[`item.logged_at`]="{ item }">
              {{ moment(item.logged_at).format('DD/MM/YYYY HH:mm:ss') }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="colorStatus(item.status)"
                small
                class="mb-2 pl-4 pr-4 my-1"
              >
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.data`]="{ item }">
              <data-dialog
                :item="item"
              />
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import LogsStore, { BOOTSTRAP } from '@/store/modules/logs'
  import { mapState, mapActions } from 'vuex'
  import { StatusLog } from '../../utils/constants'
  import DataDialog from './DataDialog'

  export default {
    name: 'Logs',

    components: {
      DataDialog,
    },

    data () {
      return {
        headers: [
          { align: 'center', text: 'Data', value: 'logged_at', sortable: true, groupable: false, floatingfilter: false, report: true },
          { align: 'left', text: 'Usuário', value: 'nomeUsuario', sortable: true, groupable: true, floatingfilter: true, report: true },
          { align: 'left', text: 'Perfil', value: 'roles[0].description', sortable: true, groupable: true, floatingfilter: true, report: true, subProperty: ['roles', 0, 'description'], styleXLSX: { type: 'subProperty' } },
          { align: 'left', text: 'Plataforma', value: 'platform', sortable: true, groupable: true, floatingfilter: true, report: true },
          { align: 'left', text: 'Evento', value: 'type', sortable: true, groupable: false, floatingfilter: true, report: true },
          { align: 'center', text: 'Versão', value: 'version', sortable: true, groupable: true, floatingfilter: true, report: true },
          { align: 'left', text: 'Status', value: 'status', sortable: true, groupable: true, floatingfilter: true, report: true },
        ],
        data_inicial: 'from',
        data_final: 'to',
      }
    },

    computed: {
      ...mapState('logs', ['logs', 'loading']),
    },

    created () {
      if (!this.$store.hasModule('logs')) { this.$store.registerModule('logs', LogsStore) }

      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 3)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = data_i
      this.data_final = data_f

      this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
    },

    methods: {
      ...mapActions('logs', [BOOTSTRAP]),

      itemRowBackground: function (item) {
        if (item.status === StatusLog.FINALIZADO) {
          return 'green lighten-5'
        } else if (item.status === StatusLog.CANCELADO || item.status === StatusLog.ERROR) {
          return 'red lighten-5'
        } else if (item.status === StatusLog.EM_ANDAMENTO) {
          return 'yellow lighten-5'
        }
      },

      colorStatus (status) {
        if (status === StatusLog.FINALIZADO) {
          return 'success'
        } else if (status === StatusLog.CANCELADO || status === StatusLog.ERROR) {
          return 'error'
        } else if (status === StatusLog.EM_ANDAMENTO) {
          return 'warning'
        }
      },

      handleDataFilter () {
        this.BOOTSTRAP({ data_inicial: this.data_inicial, data_final: this.data_final })
      },
    },
  }
</script>
